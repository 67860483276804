import React, { useState } from "react";
import { ethers } from "ethers";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  VStack,
  IconButton,
  HStack,
  Radio,
  RadioGroup,
  Tabs,
  TabPanels,
  TabPanel,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { SiEthereum } from "react-icons/si";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "98.css";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const PaymentMethodModal = ({
  isOpen,
  onClose,
  onBack,
  minerData,
  selectedMinerId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  minerData: {
    computingPower: string;
    totalPrice: number;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  } | null;
  selectedMinerId: string | null;
}) => {
  const [selectedCrypto, setSelectedCrypto] = useState("ETH");
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<
    "MetaMask" | "Trust Wallet" | null
  >(null);
  const toast = useToast();

  const db = getFirestore();

  const connectWallet = async () => {
    if (!window.ethereum) {
      toast({
        title: "Ethereum Wallet Required",
        description: "Please install MetaMask or Trust Wallet to connect.",
        status: "warning",
        duration: 5000,
      });
      return;
    }

    try {
      if (selectedWallet === "MetaMask" || selectedWallet === "Trust Wallet") {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        if (network.chainId !== 1) {
          // Check for Ethereum mainnet
          toast({
            title: "Unsupported Network",
            description: "Please switch to the Ethereum mainnet.",
            status: "error",
            duration: 5000,
          });
          return;
        }

        setConnected(true);
        toast({
          title: `${selectedWallet} Connected`,
          description: "You can now proceed with the payment.",
          status: "success",
          duration: 5000,
        });
      }
    } catch (error) {
      let errorMessage = "There was an issue connecting your wallet.";

      const err = error as any;
      if (err.code === 4001) {
        errorMessage = "Connection was rejected by the user.";
      } else if (err.message.includes("insufficient funds")) {
        errorMessage =
          "Insufficient funds in your wallet to complete this action.";
      } else if (err.message.includes("network")) {
        errorMessage = "Please check your network connection and try again.";
      } else if (err.message.includes("unsupported")) {
        errorMessage =
          "The selected network is unsupported. Please switch to the Ethereum network.";
      }

      toast({
        title: "Connection Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
      });
    }
  };

  const handlePayment = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!minerData || !user || !selectedMinerId) {
      toast({
        title: "Error",
        description: "No user, miner data, or selected miner ID available.",
        status: "error",
        duration: 5000,
      });
      return;
    }

    try {
      setLoading(true); // Start loading
      // Step 1: Fetch the ETH/USD exchange rate
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();
      const ethToUsdRate = data.ethereum.usd;

      // Step 2: Convert USD price to Ether
      const amountInEther = (minerData.totalPrice / ethToUsdRate).toString();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const recipientAddress = "0x72C3c6eB582d3B0E94524832f529587c67f718F7";

      const tx = await signer.sendTransaction({
        to: recipientAddress,
        value: ethers.utils.parseEther(amountInEther),
      });

      toast({
        title: "Payment Sent!",
        description: "Transaction submitted. Waiting for confirmation...",
        status: "success",
        duration: 5000,
      });

      await tx.wait();

      const minerRef = doc(db, `users/${user.uid}/miners`, selectedMinerId);
      const minerDoc = await getDoc(minerRef);

      const fixedAnnualROI = 55;
      let updatedMinerData = { ...minerData };

      if (minerDoc.exists()) {
        const existingMinerData = minerDoc.data();
        const existingComputingPower =
          parseFloat(existingMinerData.computingPower) || 0;
        const newComputingPower =
          existingComputingPower + parseFloat(minerData.computingPower);
        const newPoolPayout =
          existingMinerData.poolPayout +
          minerData.totalPrice * (fixedAnnualROI / 100);
        const newMaintenanceCost =
          existingMinerData.maintenanceCost + minerData.maintenanceCost;
        const newNetReward = newPoolPayout - newMaintenanceCost;

        updatedMinerData = {
          computingPower: `${newComputingPower.toFixed(2)} TH`,
          totalPrice: parseFloat(minerData.totalPrice.toFixed(2)),
          poolPayout: parseFloat(newPoolPayout.toFixed(2)),
          maintenanceCost: parseFloat(newMaintenanceCost.toFixed(2)),
          netReward: parseFloat(newNetReward.toFixed(2)),
          annualROI: fixedAnnualROI,
          imageUrl: existingMinerData.imageUrl || "",
        };
      }

      await setDoc(minerRef, updatedMinerData);

      toast({
        title: "Payment Confirmed!",
        description: "Miner data has been updated successfully.",
        status: "success",
        duration: 5000,
      });

      onClose();
    } catch (error) {
      let errorMessage = "There was an issue processing your payment.";

      const err = error as any;
      if (err.code === "INSUFFICIENT_FUNDS") {
        errorMessage = "You have insufficient funds for this transaction.";
      } else if (err.message.includes("user rejected")) {
        errorMessage = "Payment was rejected by the user.";
      } else if (err.message.includes("network")) {
        errorMessage = "Network error. Please check your connection.";
      } else if (err.message.includes("gas")) {
        errorMessage =
          "Insufficient gas. Please ensure enough ETH for gas fees.";
      }

      toast({
        title: "Payment Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        className="window"
        style={{
          width: "500px",
          borderRadius: 0,
          padding: "10px",
          backgroundColor: "#4A8A94",
        }}
      >
        <ModalHeader className="title-bar" style={{ padding: "0.5rem" }}>
          <div className="title-bar-text">Payment Method</div>
          <div className="title-bar-controls">
            <button aria-label="Close" onClick={onClose}></button>
          </div>
        </ModalHeader>

        <ModalBody
          style={{
            padding: "1rem",
            backgroundColor: "#4A8A94",
          }}
        >
          <IconButton
            aria-label="Go Back"
            icon={<FaArrowLeft />}
            className="button-98"
            onClick={onBack}
            style={{
              width: "40px",
              padding: "5px",
              borderRadius: 0,
              marginRight: "5px",
              marginBottom: "10px",
            }}
          />

          <Text mb={4}>Choose Wallet Provider</Text>
          <RadioGroup
            onChange={(value) =>
              setSelectedWallet(value as "MetaMask" | "Trust Wallet")
            }
            value={selectedWallet || ""}
          >
            <VStack align="flex-start" spacing={4}>
              <Radio value="MetaMask">MetaMask</Radio>
              <Radio value="Trust Wallet">Trust Wallet</Radio>
            </VStack>
          </RadioGroup>

          <Tabs variant="unstyled" mt={4}>
            <TabPanels>
              <TabPanel>
                <Text mb={2}>Choose your cryptocurrency</Text>
                <RadioGroup onChange={setSelectedCrypto} value={selectedCrypto}>
                  <VStack align="flex-start" spacing={4}>
                    <HStack className="field-98">
                      <Radio value="ETH" className="radio-98">
                        <HStack>
                          <SiEthereum color="green" />
                          <Box>
                            <Text>Ethereum</Text>
                            <Text fontSize="sm" color="gray.600">
                              ERC-20 / BEP-20
                            </Text>
                          </Box>
                        </HStack>
                      </Radio>
                    </HStack>
                  </VStack>
                </RadioGroup>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <VStack spacing={4} mt={8} align="flex-start">
            <Text fontSize="sm">
              The payment will be processed on-chain. Please connect your
              Ethereum wallet to proceed.
            </Text>
            <HStack justifyContent="space-between" w="100%">
              <Text>Total</Text>
              <Text>${minerData?.totalPrice || 0}</Text>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter className="window" style={{ padding: 0 }}>
          <button
            onClick={connected ? handlePayment : connectWallet}
            className="button"
            style={{
              width: "100%",
              height: "40px",
              fontSize: "16px",
              backgroundColor: "#4A8A94",
            }}
            disabled={loading || !selectedWallet}
          >
            {loading ? (
              <>
                Processing... <Spinner size="sm" ml={2} />
              </>
            ) : connected ? (
              "Pay Now"
            ) : (
              "Connect Wallet"
            )}
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentMethodModal;
