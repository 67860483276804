import React from "react";
import { NavLink } from "react-router-dom";
import "98.css";

const Footer = () => {
  return (
    <div
      className="window"
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "8px",
        backgroundColor: "#4A8A94",
      }}
    >
      <div className="window-body">
        {/* Left Side - Menu Items */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
          }}
        >
          <div
            className="toolbar"
            style={{ display: "flex", gap: "20px", margin: "auto" }}
          >
            <NavLink
              to="https://t.me/+VLUXL4VvNqVlMDc8"
              target="_blank"
              className="button"
            >
              Telegram
            </NavLink>
            <NavLink
              to="https://crypto-farm-1.gitbook.io/crypto-farm-docs"
              target="_blank"
              className="button"
            >
              Docs
            </NavLink>
            <NavLink to="/" className="button">
              Twitter
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
