import React from "react";
import {
  Box,
  Text,
  Avatar,
  HStack,
  VStack,
  Badge,
  Link,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaCopy, FaEdit } from "react-icons/fa";
import TopNavbar from "../components/TopNavbar";

const ProfilePage = () => {
  // Colors from your theme
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box bg={bgColor} minH="100vh" p={6}>
      <TopNavbar />
      <Box p={8} mt={10}>
        <HStack spacing={6} align="stretch">
          {/* User Info Card */}
          <Box
            bg={cardBgColor}
            p={6}
            rounded="lg"
            boxShadow="md"
            border="1px solid"
            borderColor={borderColor}
          >
            <HStack justify="space-between" spacing={20} align="top">
              <HStack spacing={4}>
                <Avatar size="lg" />
                <VStack align="start" spacing={1}>
                  <HStack>
                    <HStack>
                      <Text fontWeight="bold" fontSize="lg" color={textColor}>
                        f5c74571
                      </Text>
                      <Badge colorScheme="gray">NOT VERIFIED</Badge>
                    </HStack>
                  </HStack>
                  <HStack spacing={2}>
                    <HStack>
                      <Text>ID: </Text>
                      <Text fontWeight={500}>563605</Text>
                    </HStack>
                    <IconButton
                      aria-label="Copy ID"
                      icon={<FaCopy />}
                      size="sm"
                      variant="ghost"
                      colorScheme="gray"
                    />
                  </HStack>
                  <HStack>
                    <Text>username: </Text>
                    <Text fontWeight={500}>TheBlockchainBeast</Text>
                  </HStack>
                  <HStack>
                    <Text>Email: </Text>
                    <Text fontWeight={500}>Wisdomifenkwe011@gmail.com</Text>
                  </HStack>
                </VStack>
              </HStack>
              <IconButton
                aria-label="Edit Profile"
                icon={<FaEdit />}
                variant="ghost"
                colorScheme="gray"
              />
            </HStack>
          </Box>

          {/* Referral Program */}
          <Box
            bg={cardBgColor}
            p={6}
            rounded="lg"
            boxShadow="md"
            border="1px solid"
            borderColor={borderColor}
          >
            <Text fontWeight="bold" fontSize="lg" mb={2} color={textColor}>
              Referral Program
            </Text>
            <Text color={textColor}>
              Share your link with friends and get 5% of all their created
              miners.
            </Text>
            <HStack mt={4} justify="space-between">
              <Link color="teal.500">https://yourapp.io/?ref=vnCEW</Link>
              <IconButton
                aria-label="Copy Referral Link"
                icon={<FaCopy />}
                size="sm"
                variant="ghost"
                colorScheme="gray"
              />
            </HStack>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default ProfilePage;
