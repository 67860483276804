import React, { useState, useEffect } from "react";
import TopNavbar from "../components/TopNavbar";
import {
  getFirestore,
  collection,
  // getDocs,
  onSnapshot,
} from "firebase/firestore";
import Footer from "../components/Footer";
import { getAuth } from "firebase/auth";
import "98.css";

const RewardsPage = () => {
  const [rewardData, setRewardData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const minersCollectionRef = collection(db, `users/${user.uid}/miners`);

      // Set up a Firestore listener for real-time updates
      const unsubscribe = onSnapshot(minersCollectionRef, (snapshot) => {
        const minerList = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            name: data.name || `Miner #${doc.id.slice(-4)}`,
            date: data.dateAdded || new Date().toISOString().split("T")[0],
            computingPower: `${data.computingPower || "N/A"} `,
            reward: `$${data.netReward || "0"}`,
            pr: `$${data.poolPayout || "0"}`,
            tokenDiscount: "5%",
            address: data.walletAddress || "N/A",
            status: "Pending", // Set all statuses to "Pending"
          };
        });
        setRewardData(minerList);
        setLoading(false);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, []);

  // useEffect(() => {
  //   const fetchMinerData = async () => {
  //     setLoading(true);
  //     try {
  //       const db = getFirestore();
  //       const auth = getAuth();
  //       const user = auth.currentUser;

  //       if (user) {
  //         const minersCollectionRef = collection(
  //           db,
  //           `users/${user.uid}/miners`
  //         );
  //         const minerSnapshot = await getDocs(minersCollectionRef);

  //         const minerList = minerSnapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           return {
  //             date: data.dateAdded || new Date().toISOString().split("T")[0], // Assuming 'dateAdded' or using today's date
  //             computingPower: `${data.computingPower || "N/A"} `,
  //             reward: `$${data.netReward || "0"}`,
  //             pr: `$${data.poolPayout || "0"}`,
  //             service: "10%",
  //             tokenDiscount: "5%",
  //             address: data.walletAddress || "N/A",
  //             status: "Pending", // Set all statuses to "Pending"
  //           };
  //         });

  //         setRewardData(minerList);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching miner data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchMinerData();
  // }, []);

  return (
    <div
      className="window"
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#4A8A94" }}
    >
      <TopNavbar />

      <div className="window-body" style={{ padding: "20px" }}>
        {/* Date Filter Section */}
        <div
          className="toolbar"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "15px",
            marginBottom: "30px",
            padding: "10px 0",
            fontSize: "18px",
          }}
        >
          <input
            type="date"
            className="input"
            placeholder="End Date"
            style={{ padding: "10px", fontSize: "16px" }}
          />
          <button
            className="button"
            style={{ padding: "10px 20px", fontSize: "16px" }}
          >
            Filter
          </button>
        </div>

        {/* Table Section */}
        <div
          className="window"
          style={{
            overflowX: "auto",
            marginTop: "20px",
            padding: "20px",
            backgroundColor: "#4A8A94",
          }}
        >
          {loading ? (
            <div
              className="window"
              style={{ textAlign: "center", padding: "40px" }}
            >
              <div className="window-body">
                <p style={{ fontSize: "18px" }}>Loading...</p>
              </div>
            </div>
          ) : (
            <table
              className="table"
              style={{
                width: "100%",
                fontSize: "16px",
                backgroundColor: "#4A8A94",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#4A8A94",
                    fontSize: "18px",
                    padding: "10px",
                  }}
                >
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Miner Name
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Date
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Computing Power
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Reward
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    PR
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Discount
                  </th>
                  <th style={{ padding: "15px", backgroundColor: "#4A8A94" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {rewardData.map((reward, index) => (
                  <tr key={index} style={{ padding: "10px" }}>
                    <td style={{ padding: "15px" }}>{reward.name}</td>
                    <td style={{ padding: "15px" }}>{reward.date}</td>
                    <td style={{ padding: "15px" }}>{reward.computingPower}</td>
                    <td style={{ padding: "15px" }}>{reward.reward}</td>
                    <td style={{ padding: "15px" }}>{reward.pr}</td>
                    <td style={{ padding: "15px" }}>{reward.tokenDiscount}</td>
                    <td style={{ padding: "15px" }}>{reward.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RewardsPage;
