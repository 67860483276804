import React, { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import TopNavbar from "../components/TopNavbar";
import MinerCreationModal from "../components/MinerCreationModal";
import PaymentMethodModal from "../components/PaymentMethodModal";
import MinerUpgradeSelectionModal from "../components/MinerUpgradeSelectionModal";
import MinerUpgradeDetailsModal from "../components/MinerUpgradeDetailsModal";
import Footer from "../components/Footer";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { FaBitcoin } from "react-icons/fa";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { RiUserLine } from "react-icons/ri";
import { TbPlug } from "react-icons/tb";
import axios from "axios";
import "98.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  IconButton,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import HowToUseDapp from "../components/HowToUseDapp";

// Register necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Custom Chart Options
const chartOptions: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: { color: "#fff", font: { family: "Arial", size: 12 } },
      grid: { display: false },
    },
    y: {
      ticks: { color: "#fff", font: { family: "Arial", size: 12 } },
      grid: { color: "#D3D3D3", tickBorderDash: [3, 3] },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#1c1c1e",
      titleColor: "#fff",
      bodyColor: "#fff",
      borderColor: "#333",
      borderWidth: 1,
      cornerRadius: 8,
      padding: 10,
    },
  },
};

const DashboardPage = () => {
  const [minersData, setMinersData] = useState<any[]>([]);
  const [isMinerModalOpen, setIsMinerModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isUpgradeDetailsModalOpen, setIsUpgradeDetailsModalOpen] =
    useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedMinerId, setSelectedMinerId] = useState<string | null>(null);
  const [originModal, setOriginModal] = useState<"miner" | "upgrade" | null>(
    null
  ); // New state to track the origin of the Payment Modal
  const [minerData, setMinerData] = useState<{
    computingPower: string;
    totalPrice: number;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  } | null>(null); // State to track the miner data
  const [ethPrice, setEthPrice] = useState<number>(0);
  const [totalAccumulatedReward, setTotalAccumulatedReward] =
    useState<number>(0); // Track accumulated rewards
  const [rewardsChartData, setRewardsChartData] = useState<any>(null);
  const [computingPowerChartData, setComputingPowerChartData] =
    useState<any>(null);
  const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
  // const toast = useToast();
  const db = getFirestore();

  // Define the Miner interface
  interface Miner {
    id: string;
    computingPower: number;
    poolPayout: number;
    efficiency: number;
    date: string;
  }

  // Function to generate chart data
  const generateChartData = useCallback((miners: Miner[]) => {
    const rewardLabels = miners.map((miner) => miner.date);
    const rewardValues = miners.map((miner) => miner.poolPayout);

    const rewardsChart = {
      labels: rewardLabels,
      datasets: [
        {
          label: "Rewards (USDT)",
          data: rewardValues,
          borderColor: "#A020F0",
          backgroundColor: "rgba(160, 32, 240, 0.3)",
          borderWidth: 4,
          pointBorderWidth: 4,
          pointRadius: 6,
          pointHoverRadius: 8,
          pointBackgroundColor: "#A020F0",
          pointHoverBackgroundColor: "#A020F0",
          pointHoverBorderColor: "#fff",
          tension: 0.4,
          fill: true,
        },
      ],
    };

    const computingPowerLabels = miners.map((miner) => miner.date);
    const computingPowerValues = miners.map((miner) => miner.computingPower);

    const computingPowerChart = {
      labels: computingPowerLabels,
      datasets: [
        {
          label: "Computing Power (TH/s)",
          data: computingPowerValues,
          borderColor: "#32CD32",
          backgroundColor: "rgba(50, 205, 50, 0.3)",
          borderWidth: 4,
          pointBorderWidth: 4,
          pointRadius: 6,
          pointHoverRadius: 8,
          pointBackgroundColor: "#32CD32",
          pointHoverBackgroundColor: "#32CD32",
          pointHoverBorderColor: "#fff",
          tension: 0.4,
          fill: true,
        },
      ],
    };

    setRewardsChartData(rewardsChart);
    setComputingPowerChartData(computingPowerChart);
  }, []);

  // Fetch ETH price
  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price",
          {
            params: { ids: "bitcoin", vs_currencies: "usd" },
          }
        );
        setEthPrice(response.data.bitcoin.usd);
      } catch (error) {
        console.error("Error fetching ETH price:", error);
      }
    };

    fetchEthPrice();
  }, []);

  // Fetch miner data
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const minersCollectionRef = collection(db, `users/${user.uid}/miners`);
      const rewardsDocRef = doc(db, `users/${user.uid}/rewards`, "accumulated"); // Document to store total rewards

      const initializeRewards = async () => {
        const rewardsDoc = await getDoc(rewardsDocRef);
        if (rewardsDoc.exists()) {
          setTotalAccumulatedReward(
            rewardsDoc.data().totalAccumulatedReward || 0
          );
        }
      };

      initializeRewards();

      // Set up Firestore listener for real-time updates
      const unsubscribe = onSnapshot(minersCollectionRef, (snapshot) => {
        const minerList = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            computingPower: parseFloat(data.computingPower) || 0,
            poolPayout: parseFloat((data.poolPayout / 365).toString()) || 0,
            efficiency: parseFloat(data.efficiency) || 0,
            date: data.date || new Date().toISOString().split("T")[0],
          };
        });

        setMinersData(minerList);
        const initialTotalReward = minerList.reduce(
          (acc, miner) => acc + miner.poolPayout,
          0
        );
        setTotalAccumulatedReward(initialTotalReward);
        generateChartData(minerList);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [db, generateChartData]);

  // Accumulate daily rewards every 24 hours and store in Firestore
  useEffect(() => {
    const accumulateAndStoreRewards = async () => {
      // Calculate total daily reward with default to 0 if NaN
      const totalDailyReward = minersData.reduce((acc, miner) => {
        const dailyReward = parseFloat(miner.dailyReward) || 0;
        return acc + dailyReward;
      }, 0);

      // Ensure totalAccumulatedReward is a valid number
      const currentAccumulatedReward =
        parseFloat(totalAccumulatedReward.toString()) || 0;
      const newTotalReward = currentAccumulatedReward + totalDailyReward;

      setTotalAccumulatedReward(newTotalReward);

      // Update Firestore with valid accumulated reward
      const auth = getAuth();
      const user = auth.currentUser;
      if (user && !isNaN(newTotalReward)) {
        // Update only if newTotalReward is a valid number
        const rewardsDocRef = doc(
          db,
          `users/${user.uid}/rewards`,
          "accumulated"
        );
        await setDoc(rewardsDocRef, { totalAccumulatedReward: newTotalReward });
      }
    };

    // Run immediately to store the initial accumulated reward
    accumulateAndStoreRewards();

    // Then set up to accumulate and store every 24 hours
    const interval = setInterval(
      accumulateAndStoreRewards,
      24 * 60 * 60 * 1000
    );

    return () => clearInterval(interval); // Clean up on component unmount
  }, [minersData, totalAccumulatedReward, db]);

  // Convert the total accumulated reward in USD to ETH
  const totalAccumulatedRewardInETH =
    ethPrice > 0 ? (totalAccumulatedReward / ethPrice).toFixed(6) : "0.000000";

  // Handlers for opening and closing the modals
  const handleOpenMinerModal = () => {
    setIsMinerModalOpen(true);
    setIsPaymentModalOpen(false);
    setIsUpgradeModalOpen(false);
    setIsUpgradeDetailsModalOpen(false);
  };

  const handleCloseMinerModal = () => setIsMinerModalOpen(false);

  const handleOpenUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
    setIsMinerModalOpen(false);
    setIsPaymentModalOpen(false);
    setIsUpgradeDetailsModalOpen(false);
  };

  const handleCloseUpgradeModal = () => setIsUpgradeModalOpen(false);

  const handleOpenUpgradeDetailsModal = (minerId: string) => {
    console.log("Selected Miner ID:", minerId);
    setSelectedMinerId(minerId);
    setIsUpgradeModalOpen(false);
    setIsUpgradeDetailsModalOpen(true);
  };

  const handleCloseUpgradeDetailsModal = () =>
    setIsUpgradeDetailsModalOpen(false);

  const handleOpenPaymentModalFromUpgrade = (data: {
    upgradeType: string;
    price: number;
    newPower: string;
    totalCost: number;
    imageUrl: string;
  }) => {
    // Map the upgrade data to the format needed for PaymentMethodModal
    const upgradeMinerData = {
      computingPower: data.newPower,
      totalPrice: data.totalCost,
      poolPayout: 0,
      maintenanceCost: 0,
      netReward: 0,
      annualROI: 0,
      imageUrl: data.imageUrl,
    };

    setMinerData(upgradeMinerData);
    setOriginModal("upgrade");
    setIsUpgradeDetailsModalOpen(false);
    setIsPaymentModalOpen(true);
  };

  const handleOpenPaymentModalFromCreation = (minerData: {
    computingPower: string;
    totalPrice: number;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  }) => {
    const newMinerId = String(Date.now()); // Generate a unique ID
    setSelectedMinerId(newMinerId);

    setMinerData(minerData);
    setOriginModal("miner");
    setIsMinerModalOpen(false);
    setIsPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => setIsPaymentModalOpen(false);

  const handleBackFromPaymentModal = () => {
    setIsPaymentModalOpen(false);
    if (originModal === "miner") {
      setIsMinerModalOpen(true);
    } else if (originModal === "upgrade") {
      setIsUpgradeDetailsModalOpen(true);
    }
  };

  const handleBackToUpgradeSelectionModal = () => {
    setIsUpgradeDetailsModalOpen(false);
    setIsUpgradeModalOpen(true);
  };

  // Function to toggle modal
  const toggleHowToModal = () => {
    setIsHowToModalOpen(!isHowToModalOpen);
  };

  return (
    <div
      className="window"
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#4A8A94" }}
    >
      <TopNavbar />

      <div className="window-body" style={{ padding: "30px" }}>
        <IconButton
          aria-label="How to Use DApp"
          icon={<InfoIcon />}
          size="md"
          colorScheme="teal"
          onClick={toggleHowToModal}
          position="absolute"
          top="100px"
          right="20px"
          zIndex="1000"
        />

        {/* How-To Modal */}
        <Modal isOpen={isHowToModalOpen} onClose={toggleHowToModal}>
          <ModalOverlay />
          <ModalContent bgGradient="linear(to-r, teal.500, blue.600)">
            <ModalBody>
              <HowToUseDapp /> {/* Component that contains the steps */}
            </ModalBody>
          </ModalContent>
        </Modal>
        <div
          className="toolbar"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          {/* Total Rewards Card */}
          <div
            className="window"
            style={{
              padding: "30px",
              flex: "1 1 calc(25% - 20px)",
              height: "150px",
              fontSize: "18px",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Total Rewards</div>
            </div>
            <div className="window-body">
              <p>
                <FaBitcoin /> {totalAccumulatedRewardInETH} BTC
              </p>
              <p>
                ≈{" "}
                {totalAccumulatedReward.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          </div>

          {/* Miners Card */}
          <div
            className="window"
            style={{
              padding: "30px",
              flex: "1 1 calc(25% - 20px)",
              height: "150px",
              fontSize: "18px",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Miners</div>
            </div>
            <div className="window-body">
              <p>
                <RiUserLine /> {minersData.length}
              </p>
              <button className="button" onClick={handleOpenMinerModal}>
                Create Miner
              </button>
            </div>
          </div>

          {/* Power Card */}
          <div
            className="window"
            style={{
              padding: "30px",
              flex: "1 1 calc(25% - 20px)",
              height: "150px",
              fontSize: "18px",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Total Power</div>
            </div>
            <div className="window-body">
              <p>
                <AiOutlineThunderbolt />{" "}
                {minersData.reduce(
                  (acc, miner) => acc + (parseFloat(miner.computingPower) || 0),
                  0
                )}{" "}
                TH/s
              </p>
              <button className="button" onClick={handleOpenUpgradeModal}>
                Upgrade
              </button>
            </div>
          </div>

          {/* Mean Efficiency Card */}
          <div
            className="window"
            style={{
              padding: "30px",
              flex: "1 1 calc(25% - 20px)",
              height: "150px",
              fontSize: "18px",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Mean Efficiency</div>
            </div>
            <div className="window-body">
              <p>
                <TbPlug /> 35 W/TH
              </p>
              <button className="button" onClick={handleOpenUpgradeModal}>
                Upgrade
              </button>
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div
          className="toolbar"
          style={{
            display: "flex",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Rewards Chart */}
          <div
            className="window"
            style={{
              padding: "30px",
              width: "50%",
              height: "400px",
              flex: "1 1 calc(25% - 20px)",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Rewards Chart</div>
            </div>
            <div className="window-body" style={{ height: "300px" }}>
              {rewardsChartData ? (
                <Line data={rewardsChartData} options={chartOptions} />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>

          {/* Computing Power Chart */}
          <div
            className="window"
            style={{
              padding: "30px",
              width: "50%",
              height: "400px",
              flex: "1 1 calc(25% - 20px)",
              backgroundColor: "#4A8A94",
            }}
          >
            <div className="title-bar">
              <div className="title-bar-text">Computing Power Chart</div>
            </div>
            <div className="window-body" style={{ height: "300px" }}>
              {computingPowerChartData ? (
                <Line data={computingPowerChartData} options={chartOptions} />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modals */}
      <MinerCreationModal
        isOpen={isMinerModalOpen}
        onClose={handleCloseMinerModal}
        onNext={handleOpenPaymentModalFromCreation}
      />

      <MinerUpgradeSelectionModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
        onSelectMiner={handleOpenUpgradeDetailsModal}
      />

      <MinerUpgradeDetailsModal
        isOpen={isUpgradeDetailsModalOpen}
        onClose={handleCloseUpgradeDetailsModal}
        onNext={handleOpenPaymentModalFromUpgrade}
        onBack={handleBackToUpgradeSelectionModal}
        selectedMinerId={String(selectedMinerId)}
      />

      <PaymentMethodModal
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        onBack={handleBackFromPaymentModal}
        minerData={minerData}
        selectedMinerId={String(selectedMinerId)}
      />
    </div>
  );
};

export default DashboardPage;
