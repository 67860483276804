import React from "react";
import { Box, Text, VStack, HStack, Icon } from "@chakra-ui/react";
import { FaWallet, FaBolt, FaChartLine, FaArrowCircleUp } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";

const HowToUseDapp = () => {
  const steps = [
    {
      icon: FaWallet,
      title: "Step 1: Connect Your Wallet",
      description:
        "Connect your Ethereum wallet (MetaMask or Trust Wallet) to start mining.",
    },
    {
      icon: FaBolt,
      title: "Step 2: Create or Upgrade a Miner",
      description:
        "Set up a new miner or upgrade an existing one for increased rewards.",
    },
    {
      icon: FaChartLine,
      title: "Step 3: Track Your Earnings",
      description:
        "View real-time charts of your mining rewards and computing power.",
    },
    {
      icon: BsFillGearFill,
      title: "Step 4: Configure Your Settings",
      description:
        "Customize settings for your miners and manage maintenance costs.",
    },
    {
      icon: FaArrowCircleUp,
      title: "Step 5: Redeem Rewards",
      description: "Once ready, redeem your rewards directly to your wallet.",
    },
  ];

  return (
    <Box mt="20px" p="30px" color="white">
      <Text fontSize="2xl" fontWeight="bold" mb="20px">
        How to Use the DApp
      </Text>
      <VStack spacing="20px" align="stretch">
        {steps.map((step, index) => (
          <HStack
            key={index}
            p="15px"
            borderRadius="8px"
            bg="rgba(255, 255, 255, 0.1)"
            spacing="20px"
          >
            <Icon as={step.icon} w={8} h={8} color="yellow.300" />
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                {step.title}
              </Text>
              <Text>{step.description}</Text>
            </Box>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default HowToUseDapp;
