import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  HStack,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "98.css";

const MinerUpgradeSelectionModal = ({
  isOpen,
  onClose,
  onSelectMiner,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSelectMiner: (minerId: string) => void;
}) => {
  const [miners, setMiners] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch miners from Firestore
  useEffect(() => {
    const fetchMiners = async () => {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        const minersCollectionRef = collection(db, `users/${user.uid}/miners`);
        const minerSnapshot = await getDocs(minersCollectionRef);

        const minerList = minerSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMiners(minerList);
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchMiners();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        className="window"
        style={{ padding: "10px", borderRadius: 0, backgroundColor: "#4A8A94" }}
      >
        <ModalHeader className="title-bar">
          <div className="title-bar-text">Upgrade Your Miner</div>
          <div className="title-bar-controls">
            <button aria-label="Close" onClick={onClose}></button>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "20px", backgroundColor: "#4A8A94" }}>
          {loading ? (
            <Spinner size="xl" />
          ) : miners.length === 0 ? (
            <Text>No miners available for upgrade</Text>
          ) : (
            miners.map((miner) => (
              <HStack
                key={miner.id}
                className="window"
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  marginBottom: "10px",
                  backgroundColor: "#4A8A94",
                }}
                onClick={() => onSelectMiner(miner.id)}
              >
                <Box
                  boxSize="50px"
                  style={{
                    backgroundImage: `url(${miner.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "50px",
                    width: "50px",
                  }}
                />
                <Box>
                  <Text style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {miner.name || `Miner #${miner.id.slice(-4)}`}
                  </Text>
                  <HStack>
                    <Text style={{ fontSize: "12px", color: "#008000" }}>
                      {miner.computingPower}
                    </Text>
                    <Text style={{ fontSize: "12px", color: "#FFA500" }}>
                      {miner.efficiency || "N/A"}
                    </Text>
                  </HStack>
                </Box>
                <Box style={{ marginLeft: "auto" }}>
                  <Text style={{ fontSize: "18px", color: "#000000" }}>→</Text>
                </Box>
              </HStack>
            ))
          )}
        </ModalBody>
        <ModalFooter className="window" style={{ padding: 0 }}>
          <button
            className="button"
            style={{
              width: "100%",
              height: "40px",
              fontSize: "16px",
              backgroundColor: "#4A8A94",
            }}
            onClick={onClose}
          >
            Cancel
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MinerUpgradeSelectionModal;
