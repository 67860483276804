import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  Text,
  VStack,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import "98.css";

const computingPowerOptions = [
  { id: 1, label: "1 TH", level: "LEVEL 1", pricePerTH: 20.99 },
  { id: 2, label: "2 TH", level: "LEVEL 2", pricePerTH: 20.95 },
  { id: 3, label: "4 TH", level: "LEVEL 3", pricePerTH: 20.75 },
  { id: 4, label: "8 TH", level: "LEVEL 4", pricePerTH: 20.5 },
  { id: 5, label: "16 TH", level: "LEVEL 5", pricePerTH: 20.3 },
  { id: 6, label: "32 TH", level: "LEVEL 6", pricePerTH: 20.0 },
];

const MinerCreationModal = ({
  isOpen,
  onClose,
  onNext,
}: {
  isOpen: boolean;
  onClose: () => void;
  onNext: (minerData: {
    computingPower: string;
    totalPrice: number;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  }) => void;
}) => {
  const [selectedId, setSelectedId] = useState(1);
  const [rewardType, setRewardType] = useState("Daily");
  const [totalPrice, setTotalPrice] = useState(0);
  const [poolPayout, setPoolPayout] = useState(0);
  const [maintenanceCost, setMaintenanceCost] = useState(0);
  const [netReward, setNetReward] = useState(0);
  const [annualROI, setAnnualROI] = useState(0);
  const [displayedIndex, setDisplayedIndex] = useState(0);
  const toast = useToast();

  const selectedPower = computingPowerOptions.find(
    (option) => option.id === selectedId
  );

  // Fetch a random image from Firebase Storage
  const fetchRandomImage = async () => {
    const db = getFirestore();
    const storage = getStorage();
    const listRef = ref(storage); // Assuming images are in this folder
    const usedImagesDocRef = doc(db, "minersMetadata", "usedImages");

    try {
      const usedImagesDoc = await getDoc(usedImagesDocRef);
      let usedImages: string[] = []; // Explicitly define usedImages as a string array

      if (usedImagesDoc.exists()) {
        usedImages = usedImagesDoc.data().images || [];
      }

      const result = await listAll(listRef);

      // Filter out images that have already been used
      const availableImages = result.items.filter(
        (item) => !usedImages.includes(item.fullPath)
      );

      // Check if there are any available images left
      if (availableImages.length === 0) {
        toast({
          title: "Error",
          description: "No available NFT to assign.",
          status: "error",
          duration: 5000,
        });
      }

      // Pick a random image from the available ones
      const randomIndex = Math.floor(Math.random() * availableImages.length);
      const imageRef = availableImages[randomIndex];

      // Get the download URL of the selected image
      const imageUrl = await getDownloadURL(imageRef);

      // Mark this image as used by adding it to Firestore
      await updateDoc(usedImagesDocRef, {
        images: arrayUnion(imageRef.fullPath),
      });

      return imageUrl;
    } catch (error) {
      console.error("Error fetching random image:", error);
      return ""; // Return empty string in case of error
    }
  };

  // Calculating the total price based on selected computing power
  useEffect(() => {
    if (selectedPower) {
      const power = parseInt(selectedPower.label);
      const price = selectedPower.pricePerTH * power;
      setTotalPrice(Number(price.toFixed(2)));

      // Fixed annual ROI
      const fixedAnnualROI = 55; // 55%

      // Calculate annual net reward directly from the ROI and total price
      const annualNetReward = (fixedAnnualROI / 100) * price;
      const annualMaintenance = 0.03 * power * 365;

      // Display values based on the selected reward type
      let payout = 0;
      let maintenance = 0;
      let netReward = 0;
      if (rewardType === "Daily") {
        payout = annualNetReward / 365 + annualMaintenance / 365;
        maintenance = annualMaintenance / 365;
        netReward = annualNetReward / 365;
      } else if (rewardType === "Monthly") {
        payout = annualNetReward / 12 + annualMaintenance / 12;
        maintenance = annualMaintenance / 12;
        netReward = annualNetReward / 12;
      } else {
        payout = annualNetReward + annualMaintenance;
        maintenance = annualMaintenance;
        netReward = annualNetReward;
      }

      setPoolPayout(Number(payout.toFixed(2)));
      setMaintenanceCost(Number(maintenance.toFixed(2)));
      setNetReward(Number(netReward.toFixed(2)));
      setAnnualROI(fixedAnnualROI);
    }
  }, [rewardType, selectedId, selectedPower]);

  // Function to save the miner to Firebase
  // Function to save the miner to Firebase
  const handleSaveMiner = async () => {
    // Always use the annual values regardless of the selected reward type
    const power = parseInt(selectedPower?.label || "0");
    const price = selectedPower ? selectedPower.pricePerTH * power : 0;
    const fixedAnnualROI = 55; // 55%
    const annualNetReward = (fixedAnnualROI / 100) * price;
    const annualMaintenance = 0.03 * power * 365;

    // Fetch a random image URL, ensuring it's unique
    const randomImageUrl = await fetchRandomImage();

    if (!randomImageUrl) {
      console.error("No available image to assign.");
      return; // Exit if no image could be assigned
    }

    const minerData = {
      computingPower: selectedPower?.label || "",
      totalPrice: Number(price.toFixed(2)),
      poolPayout: Number((annualNetReward + annualMaintenance).toFixed(2)),
      maintenanceCost: Number(annualMaintenance.toFixed(2)),
      netReward: Number(annualNetReward.toFixed(2)),
      annualROI: fixedAnnualROI,
      imageUrl: randomImageUrl, // Assign unique image URL
    };
    onNext(minerData); // Move to the next step
  };

  // Navigate to the previous option
  const handlePrevious = () => {
    setDisplayedIndex((prevIndex) =>
      prevIndex === 0 ? computingPowerOptions.length - 3 : prevIndex - 3
    );
  };

  // Navigate to the next option
  const handleNext = () => {
    setDisplayedIndex((prevIndex) =>
      prevIndex + 3 >= computingPowerOptions.length ? 0 : prevIndex + 3
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        className="window"
        style={{ padding: "10px", borderRadius: 0, backgroundColor: "#4A8A94" }}
      >
        <ModalHeader className="title-bar">
          <div className="title-bar-text">Miner Creation</div>
          <div className="title-bar-controls">
            <button aria-label="Close" onClick={onClose}></button>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "20px", backgroundColor: "#4A8A94" }}>
          <VStack spacing={4}>
            <HStack spacing={2}>
              <IconButton
                className="button"
                icon={<FaArrowLeft />}
                aria-label="Previous Option"
                onClick={handlePrevious}
                style={{
                  width: "40px",
                  padding: "5px",
                  borderRadius: 0,
                  marginRight: "5px",
                }}
              />
              {computingPowerOptions
                .slice(displayedIndex, displayedIndex + 3)
                .map((option) => (
                  <button
                    key={option.id}
                    className={`button ${
                      selectedId === option.id ? "active" : ""
                    }`}
                    onClick={() => setSelectedId(option.id)}
                    style={{
                      width: "70px",
                      height: "40px",
                      padding: "5px",
                      borderRadius: 0,
                      marginRight: "5px",
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              <IconButton
                className="button"
                icon={<FaArrowRight />}
                aria-label="Next Option"
                onClick={handleNext}
                style={{
                  width: "40px",
                  padding: "5px",
                  borderRadius: 0,
                  marginRight: "5px",
                }}
              />
            </HStack>

            <Tabs variant="unstyled">
              <TabList className="tab-buttons" style={{ marginTop: "20px" }}>
                <Tab
                  className="button"
                  _selected={{ background: "#c0c0c0" }}
                  onClick={() => setRewardType("Daily")}
                >
                  Daily
                </Tab>
                <Tab
                  className="button"
                  _selected={{ background: "#c0c0c0" }}
                  onClick={() => setRewardType("Monthly")}
                >
                  Monthly
                </Tab>
                <Tab
                  className="button"
                  _selected={{ background: "#c0c0c0" }}
                  onClick={() => setRewardType("Yearly")}
                >
                  Yearly
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Text>Price: ${totalPrice}</Text>
                  <Text>Pool Payout: ${poolPayout}</Text>
                  <Text>Maintenance: ${maintenanceCost}</Text>
                  <Text>Net Reward: ${netReward}</Text>
                  <Text>Annual ROI: {annualROI}%</Text>
                </TabPanel>
                <TabPanel>
                  <Text>Price: ${totalPrice}</Text>
                  <Text>Pool Payout: ${poolPayout}</Text>
                  <Text>Maintenance: ${maintenanceCost}</Text>
                  <Text>Net Reward: ${netReward}</Text>
                  <Text>Annual ROI: {annualROI}%</Text>
                </TabPanel>
                <TabPanel>
                  <Text>Price: ${totalPrice}</Text>
                  <Text>Pool Payout: ${poolPayout}</Text>
                  <Text>Maintenance: ${maintenanceCost}</Text>
                  <Text>Net Reward: ${netReward}</Text>
                  <Text>Annual ROI: {annualROI}%</Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </ModalBody>

        <ModalFooter className="window" style={{ padding: 0 }}>
          <button
            onClick={handleSaveMiner}
            className="button"
            style={{
              width: "100%",
              height: "40px",
              fontSize: "16px",
              backgroundColor: "#4A8A94",
            }}
          >
            Next
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MinerCreationModal;
