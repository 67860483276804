import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import TopNavbar from "../components/TopNavbar";
import MinerUpgradeDetailsModal from "../components/MinerUpgradeDetailsModal";
import PaymentMethodModal from "../components/PaymentMethodModal";
import MinerCreationModal from "../components/MinerCreationModal";
import Footer from "../components/Footer";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "98.css";

const MinersPage = () => {
  const [minersData, setMinersData] = useState<any[]>([]);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isMinerCreationModalOpen, setIsMinerCreationModalOpen] =
    useState(false);
  const [selectedMinerId, setSelectedMinerId] = useState<string | null>(null);
  const [upgradeData, setUpgradeData] = useState<{
    upgradeType: string;
    price: number;
    newPower: string;
    totalCost: number;
  } | null>(null);

  const toast = useToast();

  // Fetch miners data from Firestore
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getFirestore();
      const minersCollectionRef = collection(db, `users/${user.uid}/miners`);

      // Set up Firestore listener for real-time updates
      const unsubscribe = onSnapshot(
        minersCollectionRef,
        (snapshot) => {
          const minerList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMinersData(minerList);
        },
        (error) => {
          console.error("Error fetching miners:", error);
          toast({
            title: "Error",
            description: "There was an error fetching the miners.",
            status: "error",
            duration: 5000,
          });
        }
      );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [toast]);

  // Calculate total miners, power, and average efficiency
  const totalMiners = minersData.length;
  const totalPower = minersData.reduce(
    (acc, miner) => acc + parseFloat(miner.computingPower || 0),
    0
  );

  // Function to open the upgrade modal and set the selected miner
  const handleUpgradeMiner = (minerId: string) => {
    setSelectedMinerId(minerId);
    setIsUpgradeModalOpen(true);
  };

  // Function to open the miner creation modal
  const handleOpenMinerCreationModal = () => {
    setIsMinerCreationModalOpen(true);
  };

  // Function to close the miner creation modal
  const handleCloseMinerCreationModal = () => {
    setIsMinerCreationModalOpen(false);
  };

  // Function to handle miner creation
  const handleMinerCreation = (minerData: {
    computingPower: string;
    totalPrice: number;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  }) => {
    setSelectedMinerId(String(Date.now())); // Set a temporary ID for the new miner
    setIsMinerCreationModalOpen(false);
    setIsPaymentModalOpen(true);
    setUpgradeData(null); // Reset upgrade data if any

    setUpgradeData({
      upgradeType: "Creation",
      price: minerData.totalPrice,
      newPower: minerData.computingPower,
      totalCost: minerData.totalPrice,
    });
  };

  // Function to close the upgrade modal and open the payment modal
  const handleUpgradePayment = (data: {
    upgradeType: string;
    price: number;
    newPower: string;
    totalCost: number;
  }) => {
    setUpgradeData(data);
    setIsUpgradeModalOpen(false);
    setIsPaymentModalOpen(true);
  };

  // Function to close the payment modal
  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setSelectedMinerId(null);
    setUpgradeData(null);
  };

  // Map upgradeData to minerData structure
  const getMappedUpgradeData = () => {
    if (!upgradeData) return null;

    return {
      computingPower: upgradeData.newPower,
      totalPrice: upgradeData.totalCost,
      poolPayout: 0,
      maintenanceCost: 0,
      netReward: 0,
      annualROI: 0,
      imageUrl: "", // You can fill in this from your image URL from Firestore
    };
  };

  return (
    <div
      className="window"
      style={{ width: "100%", margin: "0 auto", backgroundColor: "#4A8A94" }}
    >
      <TopNavbar />

      {/* Header Section */}
      <div className="window-body" style={{ padding: "20px" }}>
        <div
          className="toolbar"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <div
              className="window"
              style={{ padding: "10px", backgroundColor: "#4A8A94" }}
            >
              <div className="title-bar-text">{totalMiners} Miners</div>
            </div>
            <div
              className="window"
              style={{ padding: "10px", backgroundColor: "#4A8A94" }}
            >
              <div className="title-bar-text">⚡ {totalPower} TH</div>
            </div>
          </div>

          <button className="button" onClick={handleOpenMinerCreationModal}>
            {/* <FaPlus style={{ marginRight: "5px" }} /> */}
            Create Miner
          </button>
        </div>

        {/* Miners Grid */}
        <div
          className="toolbar"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {minersData.map((miner) => (
            <div
              className="window"
              key={miner.id}
              style={{
                position: "relative",
                overflow: "hidden",
                height: "250px",
              }}
            >
              <div className="title-bar">
                <div className="title-bar-text">
                  {miner.name || `Miner #${miner.id.slice(-4)}`}
                </div>
              </div>
              <img
                src={miner.imageUrl}
                alt={miner.name || `Miner #${miner.id.slice(-4)}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <div
                className="window-body"
                style={{
                  position: "absolute",
                  bottom: "0",
                  width: "100%",
                  padding: "10px",
                  background: "rgba(0,0,0,0.5)",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <p>Power: {miner.computingPower}</p>
                <button
                  className="button"
                  onClick={() => handleUpgradeMiner(miner.id)}
                  style={{ color: "white" }}
                >
                  Upgrade Miner
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />

      {/* Miner Creation Modal */}
      <MinerCreationModal
        isOpen={isMinerCreationModalOpen}
        onClose={handleCloseMinerCreationModal}
        onNext={handleMinerCreation}
      />

      {/* Upgrade Miner Modal */}
      {selectedMinerId !== null && (
        <MinerUpgradeDetailsModal
          isOpen={isUpgradeModalOpen}
          onClose={() => setIsUpgradeModalOpen(false)}
          onNext={handleUpgradePayment}
          onBack={() => setIsUpgradeModalOpen(false)}
          selectedMinerId={String(selectedMinerId)}
        />
      )}

      {/* Payment Method Modal */}
      <PaymentMethodModal
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        onBack={() => {
          setIsPaymentModalOpen(false);
          setIsUpgradeModalOpen(true);
        }}
        minerData={getMappedUpgradeData()}
        selectedMinerId={String(selectedMinerId)}
      />
    </div>
  );
};

export default MinersPage;
