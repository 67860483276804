import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { getAuth, signOut } from "firebase/auth";
import "98.css";

const TopNavbar = () => {
  const navigate = useNavigate();

  // State to manage the visibility of the logout button
  const [showLogout, setShowLogout] = useState(false);

  // Handle Logout Function
  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth); // Sign out the user
    navigate("/"); // Redirect to the Auth Page
  };

  // Toggle Logout visibility when clicking the user icon
  const toggleLogoutMenu = () => {
    setShowLogout((prevShowLogout) => !prevShowLogout);
  };

  return (
    <div
      className="window"
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "8px",
        backgroundColor: "#4A8A94",
      }}
    >
      <div className="title-bar">
        <div className="title-bar-text">CryptoFarm</div>
      </div>
      <div className="window-body">
        {/* Left Side - Menu Items */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
          }}
        >
          <div className="toolbar" style={{ display: "flex", gap: "20px" }}>
            <NavLink to="/dashboard" className="button">
              Dashboard
            </NavLink>
            <NavLink to="/miners" className="button">
              Miners
            </NavLink>
            <NavLink to="/rewards" className="button">
              Rewards
            </NavLink>
          </div>

          {/* Right Side - Wallet Popup and User Profile Icon */}
          <div style={{ display: "flex", gap: "20px" }}>
            {/* User Profile Icon */}
            <div
              className="window"
              style={{ padding: "5px", position: "relative" }}
              onClick={toggleLogoutMenu}
            >
              <FaUserCircle size={20} />

              {/* Logout Button (only visible when showLogout is true) */}
              {showLogout && (
                <div
                  className="window-body"
                  style={{
                    position: "absolute",
                    top: "35px",
                    right: "0",
                    zIndex: "999",
                    background: "#fff",
                    padding: "10px",
                  }}
                >
                  <button className="button" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
