import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Box,
  HStack,
  VStack,
  Text,
  Divider,
  Radio,
  RadioGroup,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";

type UpgradeType = "Power S" | "Power M" | "Power L" | "Power XL";

const MinerUpgradeDetailsModal = ({
  isOpen,
  onClose,
  onNext,
  selectedMinerId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onNext: (data: {
    upgradeType: string;
    price: number;
    powerIncrease: number;
    totalCost: number;
    newPower: string;
    poolPayout: number;
    maintenanceCost: number;
    netReward: number;
    annualROI: number;
    imageUrl: string;
  }) => void;
  onBack: () => void;
  selectedMinerId: string;
}) => {
  const [selectedUpgrade, setSelectedUpgrade] =
    useState<UpgradeType>("Power S");
  const [miner, setMiner] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    if (!selectedMinerId) return;

    const db = getFirestore();
    const minerRef = doc(
      db,
      `users/${getAuth().currentUser?.uid}/miners`,
      selectedMinerId
    );

    // Set up Firestore real-time listener
    const unsubscribe = onSnapshot(
      minerRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setMiner(docSnapshot.data());
          setLoading(false);
        } else {
          console.error("No such miner found!");
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error fetching miner:", error);
        toast({
          title: "Error",
          description: "There was an error fetching the miner details.",
          status: "error",
          duration: 5000,
        });
        setLoading(false);
      }
    );

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [selectedMinerId, toast]);

  // Upgrade options definition
  const upgradeOptions: Record<
    UpgradeType,
    { price: number; powerIncrease: number }
  > = {
    "Power S": { price: 39.99, powerIncrease: 2 },
    "Power M": { price: 99.99, powerIncrease: 5 },
    "Power L": { price: 199.99, powerIncrease: 10 },
    "Power XL": { price: 399.99, powerIncrease: 20 },
  };

  const handleNext = () => {
    if (!miner) return;

    const selectedUpgradeData = upgradeOptions[selectedUpgrade];
    const currentPower = parseFloat(miner.computingPower) || 0;
    const newComputingPower = currentPower + selectedUpgradeData.powerIncrease;

    // Fixed annual ROI
    const fixedAnnualROI = 55; // 55%

    // Calculate annual net reward directly from the ROI and price of the upgrade
    const annualNetReward = (fixedAnnualROI / 100) * selectedUpgradeData.price;

    // Maintenance costs based on the new computing power
    const dailyMaintenance = 0.03 * newComputingPower;
    const annualMaintenance = dailyMaintenance * 365;

    // Accumulate with existing values
    const existingPoolPayout = parseFloat(miner.poolPayout) || 0;
    const existingMaintenanceCost = parseFloat(miner.maintenanceCost) || 0;
    const existingNetReward = parseFloat(miner.netReward) || 0;

    // Add the new upgrade values to existing ones
    const newPoolPayout = existingPoolPayout + annualNetReward;
    const newMaintenanceCost = existingMaintenanceCost + annualMaintenance;
    const newNetReward =
      existingNetReward + (annualNetReward - annualMaintenance);

    // Pass the accumulated and updated values to the Payment Modal, including the imageUrl
    onNext({
      upgradeType: selectedUpgrade,
      price: selectedUpgradeData.price,
      powerIncrease: selectedUpgradeData.powerIncrease,
      totalCost: selectedUpgradeData.price,
      newPower: `${newComputingPower.toFixed(2)} TH`,
      poolPayout: Number(newPoolPayout.toFixed(2)),
      maintenanceCost: Number(newMaintenanceCost.toFixed(2)),
      netReward: Number(newNetReward.toFixed(2)),
      annualROI: fixedAnnualROI,
      imageUrl: miner.imageUrl,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        className="window"
        style={{ padding: "10px", borderRadius: 0, backgroundColor: "#4A8A94" }}
      >
        <div className="title-bar">
          <div className="title-bar-text">Upgrade your miner</div>
          <div className="title-bar-controls">
            <button aria-label="Close" onClick={onClose}></button>
          </div>
        </div>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

        <ModalBody style={{ backgroundColor: "#4A8A94", padding: "15px" }}>
          {loading ? (
            <Spinner size="lg" />
          ) : !miner ? (
            <Text>No miner details found</Text>
          ) : (
            <>
              <HStack
                className="window"
                style={{
                  padding: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#4A8A94",
                  border: "none",
                }}
              >
                <Box
                  boxSize="50px"
                  style={{
                    backgroundImage: `url(${miner.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "50px",
                    width: "50px",
                  }}
                />
                <Box>
                  <Text style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {miner.name || `Miner #${selectedMinerId.slice(-4)}`}
                  </Text>
                  <HStack>
                    <Text style={{ fontSize: "12px", color: "#008000" }}>
                      {miner.computingPower || "N/A"}
                    </Text>
                  </HStack>
                </Box>
              </HStack>

              <VStack spacing={4} align="stretch">
                <RadioGroup
                  onChange={(value) => setSelectedUpgrade(value as UpgradeType)}
                  value={selectedUpgrade}
                >
                  <VStack spacing={4} align="stretch">
                    {Object.keys(upgradeOptions).map((upgradeKey) => (
                      <Box
                        key={upgradeKey}
                        className="window"
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          backgroundColor: "#4A8A94",
                          border: "none",
                        }}
                      >
                        <HStack justifyContent="space-between">
                          <VStack align="flex-start" style={{ width: "100%" }}>
                            <HStack>
                              <Radio value={upgradeKey} />
                              <Text style={{ fontWeight: "bold" }}>
                                {upgradeKey}
                              </Text>
                            </HStack>
                            <Text>
                              {miner.computingPower} {`>>`}{" "}
                              {(
                                parseFloat(miner.computingPower) +
                                upgradeOptions[upgradeKey as UpgradeType]
                                  .powerIncrease
                              ).toFixed(2)}{" "}
                              TH
                            </Text>
                          </VStack>
                          <VStack align="flex-end">
                            <Text
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {upgradeOptions[upgradeKey as UpgradeType].price}$
                            </Text>
                          </VStack>
                        </HStack>
                      </Box>
                    ))}
                  </VStack>
                </RadioGroup>
              </VStack>
            </>
          )}
        </ModalBody>

        <ModalFooter
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <HStack justifyContent="space-between" style={{ padding: "10px" }}>
            <Text style={{ fontWeight: "bold" }}>Total</Text>
            <Text style={{ fontWeight: "bold" }}>
              {upgradeOptions[selectedUpgrade].price}$
            </Text>
          </HStack>

          <button
            className="button"
            style={{
              width: "100%",
              padding: "10px",
              fontWeight: "bold",
              fontSize: "14px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleNext}
          >
            Next
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MinerUpgradeDetailsModal;
