import React, { useState, useEffect } from "react";
import { useToast, Image } from "@chakra-ui/react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signInAnonymously,
  onAuthStateChanged,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import "98.css";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDhDnIUws0fVQ_9id3uOEWcTu0Q65ySEq8",
  authDomain: "ethmining-5d191.firebaseapp.com",
  databaseURL: "https://ethmining-5d191-default-rtdb.firebaseio.com",
  projectId: "ethmining-5d191",
  storageBucket: "ethmining-5d191.appspot.com",
  messagingSenderId: "1083517652202",
  appId: "1:1083517652202:web:9a3cbf30713cad15e51b93",
  measurementId: "G-4DLVNPCM57",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  // States for form input, loading, and password visibility
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Check if user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Redirect to dashboard if user is already authenticated
        navigate("/dashboard", { replace: true });
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // Handle login with email and password
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast({
        title: "Login successful.",
        description: "Welcome back! You've successfully logged in.",
        status: "success",
        duration: 5000,
      });
      navigate("/dashboard", { replace: true });
    } catch (error) {
      toast({
        title: "Login failed.",
        description: "Please check your email and password.",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle register with email and password
  const handleRegister = async () => {
    setIsLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast({
        title: "Account created.",
        description: "Your account has been created successfully.",
        status: "success",
        duration: 5000,
      });
      navigate("/dashboard", { replace: true });
    } catch (error) {
      toast({
        title: "Registration failed.",
        description: "Please provide a valid email and a strong password.",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsLoading(true);
    try {
      await signInWithPopup(auth, provider);
      toast({
        title: "Login successful.",
        description: "You have successfully logged in with Google.",
        status: "success",
        duration: 5000,
      });
      navigate("/dashboard", { replace: true });
    } catch (error) {
      toast({
        title: "Google Sign-In failed.",
        description: "An error occurred during Google authentication.",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Anonymous Sign-In
  const handleAnonymousSignIn = async () => {
    setIsLoading(true);
    try {
      await signInAnonymously(auth);
      toast({
        title: "Logged in anonymously.",
        description: "You have successfully logged in anonymously.",
        status: "success",
        duration: 5000,
      });
      navigate("/dashboard", { replace: true });
    } catch (error) {
      toast({
        title: "Anonymous login failed.",
        description: "An error occurred during anonymous login.",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle password visibility
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#4A8A94", // Pixelated retro background color
        fontFamily: "'Press Start 2P', sans-serif", // Pixelated font
        imageRendering: "pixelated", // Forces pixel rendering
      }}
    >
      <div className="window" style={{ width: "400px", padding: "10px" }}>
        <Image mb={3} src="/logo.jpeg" />
        <div className="title-bar">
          <div className="title-bar-text">CryptoFarm</div>
        </div>
        <div className="window-body">
          <div className="field-row" style={{ marginBottom: "10px" }}>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: "100%",
                fontFamily: "inherit",
                fontSize: "14px",
              }}
            />
          </div>
          <div className="field-row" style={{ marginBottom: "10px" }}>
            <label>Password:</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                fontFamily: "inherit",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%",
                  fontFamily: "inherit",
                  fontSize: "14px",
                }}
              />
              <button
                className="button"
                onClick={toggleShowPassword}
                style={{
                  marginLeft: "5px",
                  border: "none",
                  background: "none",
                  padding: "5px",
                  fontFamily: "inherit",
                  cursor: "pointer",
                }}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              fontFamily: "inherit",
            }}
          >
            <button
              className="button"
              onClick={handleLogin}
              disabled={isLoading}
              style={{ width: "48%" }}
            >
              Log In
            </button>
            <button
              className="button"
              onClick={handleRegister}
              disabled={isLoading}
              style={{ width: "48%" }}
            >
              Register
            </button>
          </div>

          <hr style={{ margin: "10px 0" }} />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="button"
              onClick={handleGoogleSignIn}
              disabled={isLoading}
              style={{ width: "48%", margin: "auto" }}
            >
              Sign in with Google
            </button>
            {/* <button
              className="button"
              onClick={handleAnonymousSignIn}
              disabled={isLoading}
              style={{ width: "48%" }}
            >
              Anonymous Sign In
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
